/**
 * Created by jerry on 2020/06/19.
 * 储值卡销售提佣 api
 */
import * as API from '@/api/index'

export default {
    // 39.1.获取储值卡消耗项目组织单位提成方案列表
    getTreatSavingCardProjectCommissionScheme: params => {
        return API.POST('api/treatSavingCardProjectCommissionScheme/list', params)
    },

    // 39.2.创建储值卡消耗项目组织单位提成方案
    createTreatSavingCardProjectCommissionScheme: params => {
        return API.POST('api/treatSavingCardProjectCommissionScheme/create', params)
    },

    // 39.3.删除储值卡消耗项目提成方案
    deletetreatSavingCardProjectCommissionScheme: params => {
        return API.POST('api/treatSavingCardProjectCommissionScheme/delete', params)
    },

    // 39.4.获取储值卡消耗项目分类提佣方案
    getTreatSavingCardProjectCategoryCommission: params => {
        return API.POST('api/treatSavingCardProjectCategoryCommission/all', params)
    },

    // 39.5.保存储值卡消耗项目分类提成方案
    updateTreatSavingCardProjectCategoryCommission: params => {
        return API.POST('api/treatSavingCardProjectCategoryCommission/update', params)
    }, 
    // 39.6.获取储值卡消耗项目分类经手人提佣方案
    getTreatSavingCardProjectCategoryHandlerCommission: params => {
        return API.POST('api/treatSavingCardProjectCategoryHandlerCommission/all', params)
    },
    //39.7.保存储值卡消耗项目分类经手人提成方案
    updateTreatSavingCardProjectCategoryHandlerCommission: params => {
        return API.POST('api/treatSavingCardProjectCategoryHandlerCommission/update', params)
    }, 
    // 39.8.获取分类下的项目提佣方案
    getTreatSavingCardProjectCommission: params => {
        return API.POST('api/treatSavingCardProjectCommission/all', params)
    },
    //39.9.保存储值卡消耗项目提成方案
    updateTreatSavingCardProjectCommission: params => {
        return API.POST('api/treatSavingCardProjectCommission/update', params)
    }, 
    // 39.10.获取项目下经手人提佣方案
    getTreatSavingCardProjectHandlerCommission: params => {
        return API.POST('api/treatSavingCardProjectHandlerCommission/all', params)
    },
    //39.11.保存项目下经手人提成方案
    updateTreatSavingCardProjectHandlerCommission: params => {
        return API.POST('api/treatSavingCardProjectHandlerCommission/update', params)
    }, 
    //39.12.获取所有项目经手人提佣方案
    getTreatSavingCardProjectSchemeHandlerCommission: params => {
        return API.POST('api/treatSavingCardProjectSchemeHandlerCommission/all', params)
    },
    //39.13.保存所有项目经手人提成方案
    updateTreatSavingCardProjectSchemeHandlerCommission: params => {
        return API.POST('api/treatSavingCardProjectSchemeHandlerCommission/update', params)
    }, 




}